import { useRequest, useUpdateEffect } from 'ahooks';
import { getData } from 'api/data';
import { number } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { useState } from 'react';
const visualMin = -100;
const visualMax = 100;
const visualMinBound = -40;
const visualMaxBound = 40;

function convertData(originList: any) {
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < originList.length; i++) {
    let node = originList[i];
    if (node) {
      let value = node.value;
      value[2] != null && value[2] < min && (min = value[2]);
      value[2] != null && value[2] > max && (max = value[2]);
    }
  }
  for (let i = 0; i < originList.length; i++) {
    let node = originList[i];
    if (node) {
      let value = node.value;
      if (value[2] != null && value[2] > 0) {
        value[3] = number.linearMap(
          value[2],
          [0, max],
          [visualMaxBound, visualMax],
          true
        );
      } else if (value[2] != null && value[2] < 0) {
        value[3] = number.linearMap(
          value[2],
          [min, 0],
          [visualMin, visualMinBound],
          true
        );
      } else {
        value[3] = 0;
      }
      if (!isFinite(value[3])) {
        value[3] = 0;
      }
      if (node.children) {
        convertData(node.children);
      }
    }
  }
}

const Demo = () => {
  const [option, setOption] = useState<any>({});
  const { data } = useRequest<any, []>(() => getData().then((data) => data), {
    pollingInterval: 3000,
  });

  useUpdateEffect(() => {
    if (data) {
      const children = data.map((v: any) => ({
        "value": [
          v.total_market_value,
          `${v.leading_stocks}:${v.leading_stocks_change_range}%`,
          v.change_range
        ],
        "name": v.industry_name,
        "id": v.ranking,
        "discretion": "net interest"
      }));
      const newData = [{
        "value": [],
        "name": "Treasury",
        "id": "branch-15",
        "discretion": null,
        "children": children
      }];
      convertData(newData);
      setOption({
        tooltip: {
          formatter: function (info: any) {
            let value = info.value;
            return `领涨股票 ${value[1]}`;
          }
        },
        series: [
          {
            name: 'ALL',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            type: 'treemap',
            label: {
              show: true,
              formatter: '{b}: {@[2]}%'
            },
            itemStyle: {
              borderColor: 'black'
            },
            visualMin: visualMin,
            visualMax: visualMax,
            visualDimension: 3,
            levels: [
              {
                itemStyle: {
                  borderWidth: 3,
                  borderColor: '#333',
                  gapWidth: 3
                }
              },
              {
                color: ['#269f3c', '#aaa', '#942e38'],
                colorMappingBy: 'value',
                itemStyle: {
                  gapWidth: 1
                }
              }
            ],
            data: newData
          }
        ]
      });
    }
  }, [data]);
  return <>
    <ReactEcharts
      style={{
        height: '100vh',
        width: '100vw',
      }}
      option={option}
    />
  </>;
};

export default Demo;