import axios from 'axios'; // 引入axios
import baseUrl from 'config/apiConfig';
console.log('baseUrl: ', baseUrl);

const service = axios.create({
  baseURL: baseUrl,
  timeout: 99999,
});

// http request 拦截器
service.interceptors.request.use(
  config => {
    // if (config.method === 'get') {
    //   config.params = {
    //     ...config.params,
    //     userId: userInfo?.userId
    //   };
    // } else {
    //   config.data = {
    //     ...config.data,
    //     userId: userInfo?.userId
    //   };
    // }
    return config;
  },
  error => {
    throw error;
  }
);

// http response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    throw error;
  }
);
export default service;
